import React from 'react';
import {ProvidedGlobalProps, withGlobalProps} from '../../../providers/globalPropsProvider';
import {WidgetProps} from '@wix/cashier-express-checkout-widget/dist/src/types/WidgetProps';
import {PaymentAuthorizedArgs} from '@wix/cashier-express-checkout-widget/dist/src/types/ExternalContract';
import {
  ShippingContactRestricted,
  ShippingContactSelectedUpdate,
} from '@wix/cashier-express-checkout-widget/dist/src/types/Shipping';
import loadable from '@loadable/component';
import {withPropsChangeListeners, WithPropsChangeListenersProps} from '../../../providers/listenToPropsChanges';
import {OnPaymentAuthorizedResult} from '@wix/cashier-express-checkout-widget/src/types/ExternalContract';
import {ShippingMethod, ShippingMethodSelectedUpdate} from '@wix/cashier-express-checkout-widget/src/types/Shipping';

const CashierExpressCheckoutWidgetLazy = loadable<WidgetProps>(() =>
  import(
    /* webpackChunkName: "CashierExpressCheckoutWidget" */ '@wix/cashier-express-checkout-widget/dist/src/lazy'
  ).then((module) => module.CashierExpressCheckoutWidget)
);

@withGlobalProps
@withPropsChangeListeners
export class CashierExpressButton extends React.Component<ProvidedGlobalProps & WithPropsChangeListenersProps> {
  public handleCashierPaymentSubmit = async (
    paymentInfo: PaymentAuthorizedArgs
  ): Promise<OnPaymentAuthorizedResult> => {
    if (paymentInfo.error) {
      return Promise.reject(paymentInfo.error);
    }

    this.props.globals.handleCashierPaymentSubmit(paymentInfo, this.props.globals.accessibilityEnabled);
    const submitPaymentResult = await this.props.waitForChange('handleCashierPaymentSubmitResult');

    return {
      result: submitPaymentResult.result,
    };
  };

  public onPaymentMethodsAmountInit = (amount: number) => {
    this.props.globals.setDynamicPaymentsMethodsAmount(amount);
  };

  private readonly onShippingMethodSelected = async (
    shippingMethod: ShippingMethod
  ): Promise<ShippingMethodSelectedUpdate> => {
    this.props.globals.cashierExpressWidgetOnShippingMethodSelected(shippingMethod);
    return this.props.waitForChange('cashierExpressWidgetOnShippingMethodSelectedResult');
  };

  public handleExpressCashierShippingAddressChange = async (
    shippingAddress: ShippingContactRestricted
  ): Promise<ShippingContactSelectedUpdate> => {
    this.props.globals.fetchPaymentBreakdownForCashierAddress(shippingAddress);
    return this.props.waitForChange('fetchPaymentBreakdownForCashierAddressResult');
  };

  public onClick = async () => {
    // todo(eran): results can't be tested with current cashier testKit because this callback doesn't actually opens cashier modal in tests
    this.props.globals.handleCashierOnClick();
    if (await this.props.waitForChange('handleCashierOnClickResult')) {
      return Promise.resolve({canceled: false});
    } else {
      return Promise.resolve({canceled: true});
    }
  };

  public render() {
    const {
      meta,
      currency,
      locale,
      requestShipping,
      buttonStyle,
      domain,
      demoMode,
    } = this.props.globals.cashierExpressCheckoutWidgetProps;
    const {paymentAmount, paymentBreakdown, orderItems} = this.props.globals.cashierExpressPaymentBreakdown;

    return (
      <CashierExpressCheckoutWidgetLazy
        key={currency}
        theme={this.props.globals.dynamicPaymentMethodsTheme}
        buttonStyle={buttonStyle}
        currency={currency}
        locale={locale}
        domain={domain}
        meta={meta}
        onClick={this.onClick}
        onPaymentAuthorized={this.handleCashierPaymentSubmit}
        onShippingContactSelected={this.handleExpressCashierShippingAddressChange}
        onPaymentMethodsAmountInit={this.onPaymentMethodsAmountInit}
        onShippingMethodSelected={this.onShippingMethodSelected}
        orderItems={orderItems}
        paymentAmount={paymentAmount}
        paymentBreakdown={paymentBreakdown}
        paymentLabel="forApplePay"
        requestShipping={requestShipping}
        demoMode={demoMode}
      />
    );
  }
}
